(function ($) {

    /*
    *  new_map
    *
    *  This function will render a Google Map onto the selected jQuery element
    *
    *  @type	function
    *  @date	8/11/2013
    *  @since	4.3.0
    *
    *  @param	$el (jQuery element)
    *  @return	n/a
    */
 if ($("body").hasClass("page-template-template-map")) {
     var new_marker = {
         //state your size parameters in terms of pixels
         size: new google.maps.Size(30, 45),
         scaledSize: new google.maps.Size(30, 45),
         origin: new google.maps.Point(0, 0)
     };

     function new_map($el) {

         // var
         var $markers = $el.find('.marker');


         // vars
         var args = {
             zoom: 17,
             center: new google.maps.LatLng(0, 0),
             mapTypeId: google.maps.MapTypeId.ROADMAP,
             styles: [
                 {
                     "elementType": "geometry",
                     "stylers": [
                         {
                             "color": "#212121"
                         }
                     ]
                 },
                 {
                     "elementType": "labels.icon",
                     "stylers": [
                         {
                             "visibility": "off"
                         }
                     ]
                 },
                 {
                     "elementType": "labels.text.fill",
                     "stylers": [
                         {
                             "color": "#757575"
                         }
                     ]
                 },
                 {
                     "elementType": "labels.text.stroke",
                     "stylers": [
                         {
                             "color": "#212121"
                         }
                     ]
                 },
                 {
                     "featureType": "administrative",
                     "elementType": "geometry",
                     "stylers": [
                         {
                             "color": "#757575"
                         }
                     ]
                 },
                 {
                     "featureType": "administrative.country",
                     "elementType": "labels.text.fill",
                     "stylers": [
                         {
                             "color": "#9e9e9e"
                         }
                     ]
                 },
                 {
                     "featureType": "administrative.land_parcel",
                     "stylers": [
                         {
                             "visibility": "off"
                         }
                     ]
                 },
                 {
                     "featureType": "administrative.locality",
                     "elementType": "labels.text.fill",
                     "stylers": [
                         {
                             "color": "#bdbdbd"
                         }
                     ]
                 },
                 {
                     "featureType": "poi",
                     "elementType": "labels.text.fill",
                     "stylers": [
                         {
                             "color": "#757575"
                         }
                     ]
                 },
                 {
                     "featureType": "poi.park",
                     "elementType": "geometry",
                     "stylers": [
                         {
                             "color": "#181818"
                         }
                     ]
                 },
                 {
                     "featureType": "poi.park",
                     "elementType": "labels.text.fill",
                     "stylers": [
                         {
                             "color": "#616161"
                         }
                     ]
                 },
                 {
                     "featureType": "poi.park",
                     "elementType": "labels.text.stroke",
                     "stylers": [
                         {
                             "color": "#1b1b1b"
                         }
                     ]
                 },
                 {
                     "featureType": "road",
                     "elementType": "geometry.fill",
                     "stylers": [
                         {
                             "color": "#2c2c2c"
                         }
                     ]
                 },
                 {
                     "featureType": "road",
                     "elementType": "labels.text.fill",
                     "stylers": [
                         {
                             "color": "#8a8a8a"
                         }
                     ]
                 },
                 {
                     "featureType": "road.arterial",
                     "elementType": "geometry",
                     "stylers": [
                         {
                             "color": "#373737"
                         }
                     ]
                 },
                 {
                     "featureType": "road.highway",
                     "elementType": "geometry",
                     "stylers": [
                         {
                             "color": "#3c3c3c"
                         }
                     ]
                 },
                 {
                     "featureType": "road.highway.controlled_access",
                     "elementType": "geometry",
                     "stylers": [
                         {
                             "color": "#4e4e4e"
                         }
                     ]
                 },
                 {
                     "featureType": "road.local",
                     "elementType": "labels.text.fill",
                     "stylers": [
                         {
                             "color": "#616161"
                         }
                     ]
                 },
                 {
                     "featureType": "transit",
                     "elementType": "labels.text.fill",
                     "stylers": [
                         {
                             "color": "#757575"
                         }
                     ]
                 },
                 {
                     "featureType": "water",
                     "elementType": "geometry",
                     "stylers": [
                         {
                             "color": "#000000"
                         }
                     ]
                 },
                 {
                     "featureType": "water",
                     "elementType": "labels.text.fill",
                     "stylers": [
                         {
                             "color": "#3d3d3d"
                         }
                     ]
                 }
             ]

         };


         // create map
         var map = new google.maps.Map($el[0], args);


         // add a markers reference
         map.markers = [];


         // add markers
         $markers.each(function () {

             add_marker($(this), map);

         });

         var myoverlay = new google.maps.OverlayView();
         myoverlay.draw = function () {
             this.getPanes().markerLayer.id = 'markerLayer';
         };
         myoverlay.setMap(map);
         // center map
         center_map(map);


         // return
         return map;

     }

     /*
     *  add_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$marker (jQuery element)
     *  @param	map (Google Map object)
     *  @return	n/a
     */

     function add_marker($marker, map) {

         // var
         var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

         // create marker
         var marker = new google.maps.Marker({
             position: latlng,
             map: map,
             optimized: false

         });


         // add to array
         map.markers.push(marker);

         // if marker contains HTML, add it to an infoWindow
         if ($marker.html()) {
             // create info window
             var infowindow = new google.maps.InfoWindow({
                 content: $marker.html()
             });

             window.iws.push(infowindow)

             // show info window when marker is clicked
             google.maps.event.addListener(marker, 'click', function () {

                 //close all
                 for (var i = 0; i < iws.length; i++) {
                     iws[i].close();
                 }
                 infowindow.open(map, marker);
                 $(".gm-style-iw").parent("div").addClass("infowindow");
             });

             google.maps.event.addListener(map, 'click', function () {
                 for (var i = 0; i < iws.length; i++) {
                     iws[i].close();
                 }
             });


         }

     }

     /*
     *  center_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	map (Google Map object)
     *  @return	n/a
     */

     function center_map(map) {

         // vars
         var bounds = new google.maps.LatLngBounds();

         // loop through all markers and create bounds
         $.each(map.markers, function (i, marker) {

             var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

             bounds.extend(latlng);

         });

         // only 1 marker?
         if (map.markers.length == 1) {
             // set center of map
             map.setCenter(bounds.getCenter());
             map.setZoom(17);
         }
         else {
             // fit to bounds
             map.fitBounds(bounds);
         }

     }

     /*
     *  document ready
     *
     *  This function will render each map when the document is ready (page has loaded)
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	5.0.0
     *
     *  @param	n/a
     *  @return	n/a
     */

     // global var
     var map = null;

     function createMap() {
         function mapFiltered() {
             window.iws = []
             $('.acf-map').each(function () {
                 map = new_map($(this));
             });
         }

         mapFiltered()
     }


     $(document).ready(createMap);

     $(window).on('resize', function () {
         google.maps.event.trigger(map, 'resize');
         center_map(map);
     });
 }
})(jQuery);
